type ApiResponse = {
  accessToken: string
  refreshToken: string
  expires: number
}

const API_REQUEST_PATH = '/refresh'

export function refreshPost(accessToken: string, refreshToken: string) {
  const formData = new FormData()
  formData.append('access_token', accessToken)
  formData.append('refresh_token', refreshToken)
  return useApiAuth<ApiResponse>(API_REQUEST_PATH, 'POST', formData)
}
